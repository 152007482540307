<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item
        :key="index"
        v-for="(item, index) in type_list"
        @click="changeType(item.index, item.min_day, item.max_day)"
        :active="selectedTabIndex === item.index"
        >{{ item.label }}</b-nav-item
      >
    </b-nav>
    <b-row>
      <b-col sm="12">
        <c-table
          selectable
          :url="'CustomerWebService/get_not_cib_loan_list'"
          :per-page="10"
          :fields="columns"
          :columns="[
            'accountNumber',
            'lastname',
            'firstname',
            'register',
            'phone',
            'expiryDate',
            'loanBalance',
          ]"
          :filename="'Хугацаа хэтэрсэн зээл'"
          hover
          bordered
          fixed
          caption=""
          :startDate="minDate"
          :endDate="maxDate"
          :selectSubmitFunction="confitCib"
          :selectedButton="{ text: 'Илгээх', icon: 'fa fa-send' }"
        ></c-table>
      </b-col>
    </b-row>

    <div>
      <b-modal ref="my-modal" size="xl" @ok="sentToCib">
        <template slot="modal-title">
          Баталгаажуулалт
        </template>
        <div class="d-block">
          <b-table
            stacked="md"
            hover
            bordered
            small
            responsive="sm"
            :items="selected"
            :fields="columns.slice(1)"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(nicename)="data">
              {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
            </template>
            <template v-slot:cell(overdueDay)="data">
              <strong class="text-danger">
                {{ data.item.overdueDay }} өдөр</strong
              >
            </template>

            <template v-slot:cell(lastLoanClass)="data">
              <b-badge variant="warning">{{
                data.item.lastLoanClassName
              }}</b-badge>
              <small class="text-muted pull-right mt-1">{{
                data.item.lastLoanClassDate
              }}</small>
            </template>
          </b-table>
          <b-row>
            <b-col md="4">
              <b-form-group label="Ангилал">
                <b-form-select
                  v-model="selectedTypeCib"
                  class="border "
                  :class="{
                    'border-secondary': selectedTypeCib,
                    'border-danger': !selectedTypeCib,
                  }"
                >
                  <option :value="null">Ангилал сонгоно уу</option>
                  <option
                    :value="item"
                    :key="index"
                    v-for="(item, index) in loanClasses"
                    >{{ item.name }}</option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <p class="text-danger mb-0">
            Та дээрх зээлийн мэдээллийг
            <strong>"Зээлийн мэдээллийн сан"</strong> - руу ангилал өөрчлөх
            хүсэлт явуулахдаа итгэлтэй байна уу?
          </p>
        </div>
        <template slot="modal-footer"></template>
      </b-modal>
    </div>
    <b-modal ref="result-modal" size="xl">
      <template slot="modal-title">
        Үр дүн
      </template>
      <div class="d-block">
        <p class="text-danger" v-if="selectedTypeCib">
          Та доорх зээлийн мэдээллийг
          <strong>"Зээлийн мэдээллийн сан"</strong> - н
          <strong class="info-text">{{ selectedTypeCib.name }}</strong> ангилал
          руу шилжүүлэх хүсэлт илгээсэн байна.
        </p>
        <b-table
          stacked="md"
          hover
          bordered
          small
          :items="responseData"
          :fields="responseColumn"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(customer)="data">
            {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname
            }}<br />
            <small class="text-muted">{{ data.item.register }}</small>
          </template>
          <template v-slot:cell(loan)="data">
            {{ data.item.loanAmount }}<br />
            <small class="text-muted">{{ data.item.expiryDate }}</small>
          </template>
          <template v-slot:cell(success)="data">
            <b-badge variant="success" v-if="data.item.success">Тийм</b-badge>
            <b-badge variant="danger" v-else>Үгүй</b-badge>
          </template>
        </b-table>
      </div>
      <template slot="modal-footer"></template>
    </b-modal>
  </div>
</template>

<script>
var convert = require("xml-to-json-promise");
import moment, { min } from "moment";
export default {
  name: "OverdueLoan.list",
  data: function() {
    return {
      columns: [
        { label: "", key: "checkbox" },
        { label: "№", key: "index", class: "text-center" },
        // { label: 'Данс', key: 'accountNumber' },
        {
          label: "Нэр",
          key: "nicename",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "register",
          sortable: true,
          sort_key: "register",
        },
        { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
        {
          label: "Дууссан хугацаа",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date",
        },
        // { label: 'Хэтэрсэн хоног', key: 'overdueDay', sortable: false },
        {
          label: "Авсан зээлийн дүн",
          key: "loanAmount",
          sortable: true,
          sort_key: "loan_amount",
          class: "text-right",
        },
        {
          label: "Зээлийн үлдэгдэл дүн",
          key: "loanBalance",
          sortable: true,
          sort_key: "loan_balance",
          class: "text-right",
        },
        { label: "ЗМС", key: "lastLoanClass" },
      ],
      responseColumn: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Хэрэглэгч", key: "customer" },
        { label: "Зээл", key: "loan" },
        { label: "Амжилттай эсэх", key: "success", class: "text-center" },
        { label: "Зээлийн код", key: "loancode", class: "text-left" },
        { label: "Алдаа", key: "error", class: "text-left" },
      ],
      minDate: moment()
        .subtract(10000, "days")
        .format("YYYY-MM-DD"),
      maxDate: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      selected: [],
      loanClasses: [],
      selectedTypeCib: null,
      isLoading: false,
      sendIndex: 0,
      responseData: [],
      selectedTabIndex: 0,
      type_list: [
        {
          index: 0,
          min_day: 1,
          max_day: 10000,
          label: "Бүгд",
        },
        {
          index: 1,
          min_day: 1,
          max_day: 15,
          label: "15 хүртэл хоног",
        },
        {
          index: 2,
          min_day: 16,
          max_day: 30,
          label: "16-30 хоног",
        },
        {
          index: 3,
          min_day: 31,
          max_day: 60,
          label: "31-60 хоног",
        },
        {
          index: 4,
          min_day: 61,
          max_day: 90,
          label: "61-90 хоног",
        },
        {
          index: 5,
          min_day: 91,
          max_day: 180,
          label: "91-180 хоног",
        },
        {
          index: 6,
          min_day: 181,
          max_day: 365,
          label: "181-365 хоног",
        },
        {
          index: 7,
          min_day: 366,
          max_day: 10000,
          label: "366+ хоног",
        },
      ],
    };
  },
  created() {
    this.getLoanClass();
  },
  methods: {
    getLoanClass: function() {
      this.$http
        .get(this.$config.API_URL + "AdminWebService/get_reference", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.loanClasses = response.body.data;
          },
          (response) => {}
        );
    },
    confitCib: function(ids) {
      this.$data.selected = ids;
      this.$refs["my-modal"].show();
      this.$data.sendIndex = 0;
    },
    sentToCib: function() {
      if (this.$data.selectedTypeCib) {
        var selectedLoan = this.$data.selected[this.$data.sendIndex];
        this.isLoading = true;
        this.$http
          .post(
            this.$config.APP_URL + "CompanyWebService/update_loan_class",
            {
              loanAccountId: selectedLoan.id,
              loanClassId: this.$data.selectedTypeCib.id,
            },

            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              // //console.log(response)
              var xml = "<error>Алдаа гарсан байна</error>";
              if (response.body.responseResultType == "SUCCESS") {
                xml = response.body.successData;
              }
              convert.xmlDataToJSON(xml).then((json) => {
                var res = this.paseXmlData(json.response, selectedLoan);
                this.$data.responseData.push(res);
                if (res.success) {
                  this.lastLoanClassSave(selectedLoan.id);
                }
              });
              this.$data.sendIndex++;
              if (this.$data.sendIndex < this.$data.selected.length)
                this.sentToCib();
              else {
                this.isLoading = false;
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.$refs["result-modal"].show();
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
      } else {
        this.showToast("Анхаар", "Ангилал сонгоогүй байна", "warning");
      }
    },
    paseXmlData: function(obj, loanData) {
      // //console.log(loanData);
      var result = {
        success: false,
        error: "",
        loancode: "",
        lastname: loanData.lastname,
        firstname: loanData.firstname,
        register: loanData.register,
        loanAmount: loanData.loanAmount,
        expiryDate: loanData.expiryDate,
      };
      if (obj.result == "OK") {
        if (obj.hasOwnProperty("loanresult")) {
          result.loancode = obj.loancode[0];
          if (obj.loanresult == "OK") {
            result.success = true;
          } else {
            var errors = [];
            for (var i in obj.errors) {
              errors.push(obj.errors[i].error[0]._);
            }
            result.error = errors.join(",");
          }
        }
      } else {
        var errors = [];
        for (var i in obj.errors) {
          errors.push(obj.errors[i].error[0]._);
        }
        result.error = errors.join(",");
      }
      return result;
    },
    lastLoanClassSave: function(loanAccountId) {
      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/save_loan_class",
          { loanId: loanAccountId, loanClassId: this.$data.selectedTypeCib.id },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then((response) => {
          // //console.log(response);
        });
    },
    changeType(index, minDay, maxDay) {
      this.$data.selectedTabIndex = index;
      this.$data.minDate = moment()
        .subtract(maxDay, "days")
        .format("YYYY-MM-DD");
      this.$data.maxDate = moment()
        .subtract(minDay, "days")
        .format("YYYY-MM-DD");
    },
  },
};
</script>
